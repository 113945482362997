import React, { useState, useRef } from 'react';
import AutocompleteInput from '../Util/AutocompleteInput'; // Ajuste o caminho conforme necessário
import './FormularioAbrigo.css'
import InputMask from 'react-input-mask'; // Importação da biblioteca

function FormularioAbrigo() {
    const [dados, setDados] = useState({
        nome: '',
        email: '',
        telefone: '',
        numeroColchoes: 1,
        endereco: ''
    });
    const enderecoRef = useRef(null); // Referência para acessar o valor do endereço do componente AutocompleteInput

    function handleChange(e) {
        setDados({ ...dados, [e.target.name]: e.target.value });
    }
    function handleSubmit(e) {
        e.preventDefault();  // Impede o comportamento padrão do formulário
        // Verificações de validação
        if (!dados.nome || !dados.email || !dados.telefone || !dados.numeroColchoes || !enderecoRef.current.value || !enderecoRef.place) {
            alert('Por favor, preencha todos os campos.');
            return;
        }

        // Prepara os dados para serem enviados
        const dadosFormulario = {
            nome: dados.nome,
            email: dados.email,
            telefone: dados.telefone,
            numeroColchoes: dados.numeroColchoes,
            endereco: enderecoRef.current.value,
            latitude: enderecoRef.place.geometry.location.lat(),
            longitude: enderecoRef.place.geometry.location.lng()

        };
        console.log(enderecoRef.place.geometry.location.lat());

        if (!validarEmail(dados.email)) {
            alert('Por favor, insira um e-mail válido.');
            return;
        }

        if (!validarTelefone(dados.telefone)) {
            alert('Por favor, insira um telefone válido.');
            return;
        }


        // Faz uma requisição POST para o servidor Node.js
        fetch('/api/abrigos', {  // Use o URL correto do seu servidor
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dadosFormulario)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Sucesso:', data);
                // Adicione aqui qualquer ação de sucesso, como limpar o formulário ou mostrar uma mensagem
            })
            .catch((error) => {
                console.error('Erro:', error);
                // Adicione aqui qualquer ação de erro, como mostrar uma mensagem de erro
            });

        window.location.reload();
    }

    return (

        <div id='form' className="formulario-abrigo">
            <h2>Cadastre o Seu Abrigo!</h2>
            <div >
                <form onSubmit={handleSubmit} >
                    <input
                        type="text"
                        name="nome"
                        value={dados.nome}
                        onChange={handleChange}
                        placeholder="Nome do abrigo"
                    />
                    <input
                        type="email"
                        name="email"
                        value={dados.email}
                        onChange={handleChange}
                        placeholder="Email do abrigo"
                    />
                    <InputMask
                        mask="(99) 99999-9999"
                        value={dados.telefone}
                        onChange={handleChange}
                    >
                        {(inputProps) => (
                            <input
                                {...inputProps}
                                value={dados.telefone}
                                type="tel"
                                name="telefone"
                                placeholder="Telefone do abrigo"
                            />
                        )}
                    </InputMask>
                    <label>Número de Colchões:</label>
                    <input
                        style={{ width: '50px' }}
                        type="number"
                        name="numeroColchoes"
                        value={dados.numeroColchoes}
                        onChange={handleChange}
                        placeholder="Número de colchões"
                    />
                    <AutocompleteInput ref={enderecoRef} placeholder="Endereço do abrigo" />
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}

function validarEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validarTelefone(telefone) {
    const re = /^\(?\d{2}\)?[\s-]?[\s9]?\d{4}-?\d{4}$/;
    return re.test(telefone);
}


export default FormularioAbrigo;






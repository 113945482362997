import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, LoadScript, StandaloneSearchBox, MarkerF } from '@react-google-maps/api';
import axios from 'axios';
import Modal from 'react-modal';
import FormularioAbrigo from './components/Abrigos/FormularioAbrigo';

const mapContainerStyle = {
  height: "400px",
  width: "600px"
};

const center = {
  lat: -30.0346, // Coordenadas de Porto Alegre
  lng: -51.2177
};

function App() {
  const [map, setMap] = useState(null);
  const [position, setPosition] = useState(center);
  const [searchBox, setSearchBox] = useState(null);
  const [formData, setFormData] = useState({
    nome: '',
    contato: '',
    quantidade: 0,
    emailAbrigo: '',
    telefoneAbrigo: ''
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedAbrigo, setSelectedAbrigo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = ref => setSearchBox(ref);

  const [abrigos, setAbrigos] = useState([]);

  useEffect(() => {
    const fetchAbrigos = async () => {
      try {
        const response = await axios.get('/api/abrigos');
        console.log('Sucesso:', response.data);
        if (response.data && response.data.data) {
          setAbrigos(response.data.data);
        } else {
          console.error('Dados inesperados:', response.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Erro:', error);
        setIsLoading(false);
      }
    };

    fetchAbrigos();
  }, []);

  const onPlacesChanged = () => {
    if (searchBox && searchBox.getPlaces) {
      const places = searchBox.getPlaces();
      if (places.length === 1) {
        const loc = places[0].geometry.location;
        setPosition({
          lat: loc.lat(),
          lng: loc.lng()
        });
        map.panTo(new window.google.maps.LatLng(loc.lat(), loc.lng()));
      }
    } else {
      console.error("SearchBox não está disponível ou não foi carregado corretamente.");
    }
  };

  const handleMarkerClick = (abrigo) => {
    setSelectedAbrigo(abrigo);
    setModalIsOpen(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullData = {
      ...formData,
      localizacao: position
    };
    try {
      const response = await axios.post('/pedidos', fullData);
      alert('Pedido realizado com sucesso!');
      console.log(response.data);
    } catch (error) {
      alert('Erro ao enviar o pedido: ' + error.message);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedAbrigo(null);
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyB7325poqqbMe3bXofNdAAhle-2Fu4cYTU"
      libraries={['places']}
    >
      <div className='app-container'>
        <div className="container">
          <div className="content">
            <h1>Juntos, Podemos Restaurar o Conforto das famílias!</h1>
            <p>A CIA do SONO e a Kaizen solidarizam-se com as vítimas das recentes enchentes no Rio Grande do Sul, comprometendo-se a apoiar a reconstrução de suas vidas.</p>
            {/* <div className="buttons">
              <button className="btn-primary">Doe Agora</button>
              <button className="btn-secondary">Saiba Mais</button>
            </div> */}
          </div>
          <div className="image">
            <img src="/enchente.jpg" alt="Imagem de enchente" />
          </div>
        </div>
        <div style={{ width: '75%' }} className='map'>
          <h2>Verifique os abrigos abaixo: </h2>
          <div style={{ margin: "20px" }}>
            <StandaloneSearchBox
              onLoad={onLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <input
                type="text"
                placeholder="Buscar local do abrigo"
                style={{ boxSizing: 'border-box', width: '100%', height: '40px', paddingLeft: '12px', borderRadius: '10px' }}
              />
            </StandaloneSearchBox>
          </div>
          {/* <div style={{width: '100%'}} className='map'> */}
          {!isLoading && abrigos.length > 0 && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={position}
              zoom={10}
              onLoad={map => setMap(map)}
            >
              {abrigos.map((abrigo, index) => (
                <MarkerF
                  key={index}  // Mudando a chave para index temporariamente para verificar duplicidade
                  position={{ lat: parseFloat(abrigo.latitude), lng: parseFloat(abrigo.longitude) }}
                  onClick={() => handleMarkerClick(abrigo)}
                />
              ))}
            </GoogleMap>
          )}
          {/* </div> */}
        </div>
        {isLoading && <p>Carregando abrigos...</p>}

        <FormularioAbrigo />
        <h2>Veja a lista de abrigos completa: </h2>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Numero de Colchões</th>
              <th>Endereço</th>
            </tr>
          </thead>
          <tbody>
            {abrigos.map((item, index) => (
              <tr key={index}>
                <td>{item.nome}</td>
                <td>{item.email}</td>
                <td>{item.telefone}</td>
                <td>{item.numeroColchoes}</td>
                <td>{item.endereco}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Detalhes do Abrigo"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
      >
        {selectedAbrigo && (
          <div>
            <h2>{selectedAbrigo.nome}</h2>
            <p>Quantidade de colchões: {selectedAbrigo.numeroColchoes}</p>
            <p>Email: {selectedAbrigo.email}</p>
            <p>Telefone: <a href={`https://api.whatsapp.com/send?phone=+55${selectedAbrigo.telefone}&text=""`}> {selectedAbrigo.telefone}</a></p>
            <p>Endereço: {selectedAbrigo.endereco}</p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        )}
      </Modal>
    </LoadScript >
  );
}

export default App;

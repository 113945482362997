import React, { useEffect, forwardRef } from 'react';


const AutocompleteInput = forwardRef((props, ref) => {
    useEffect(() => {
        const autocomplete = new window.google.maps.places.Autocomplete(
            ref.current,
            { types: ['geocode'] }
        );

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            ref.place = place
        });
    }, [ref]);

    return (
        <input ref={ref} type="text" placeholder="Digite um endereço" />
    );
});

export default AutocompleteInput;